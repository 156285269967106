import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { MVideoModal } from 'ui'

export type CMastheadBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  backgroundImage?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  heading?: string
  subheading?: string
  buttonText?: string
  video?: Array<CraftEmbeddedAssetField>
}

type Props = {
  field: Array<CMastheadBlock>
}

export const CMasthead = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const content = useMemo(() => {
    return field ? field[0] : null
  }, [field])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    setAnimate(true)
  }, [])

  if (!content) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section className="c-masthead relative h-[600px] sm:h-[800px] flex sm:items-center">
      <picture>
        {!!content.backgroundImageMobile?.length && (
          <source
            media="(max-width: 640px)"
            srcSet={content.backgroundImageMobile[0].url}
          />
        )}
        {!!content.backgroundImage?.length && (
          <img
            className="absolute top-0 left-0 object-cover object-center w-full h-full"
            src={content.backgroundImage[0].url}
            alt=""
          />
        )}
      </picture>

      <div className="absolute inset-0 bg-black bg-opacity-20" />
      <div className="o-container o-container--lg z-10 mt-[110px] sm:mt-0">
        <div className="max-w-[618px]">
          {content.heading && (
            <h1
              className={classNames(
                'mb-4 font-light text-center text-white transition-all duration-300 ease-out transform text-h5 sm:text-h4 sm:text-left',
                {
                  'opacity-0 translate-y-16': !animate,
                  'opacity-100 translate-y-0': animate,
                }
              )}
              dangerouslySetInnerHTML={{ __html: content.heading ?? '' }}
            />
          )}
          {content.subheading && (
            <h2
              className={classNames(
                'mt-4 text-center text-white transition-all duration-300 ease-out delay-200 transform text-p-lg sm:mt-0 sm:text-left',
                {
                  'opacity-0 translate-y-16': !animate,
                  'opacity-100 translate-y-0': animate,
                }
              )}
            >
              {content.subheading}
            </h2>
          )}
          <div
            className={classNames(
              'flex justify-center w-full transition-all duration-300 ease-out delay-500 transform sm:block',
              {
                'opacity-0 translate-y-16': !animate,
                'opacity-100 translate-y-0': animate,
              }
            )}
          >
            {content.buttonText && (
              <button
                className="rounded-[56px] text-button font-bold text-white border-2 border-white  uppercase px-6 py-2 sm:px-8 sm:py-3 mt-4 sm:mt-6 hover:bg-white hover:text-neutral-dark-gray transition-all duration-200 ease-out"
                onClick={openModal}
              >
                {content.buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
      {content.video && (
        <MVideoModal
          open={modalOpen}
          video={content.video[0].embeddedAsset?.url}
          onClose={closeModal}
        />
      )}
    </section>
  )
}

export const CMastheadFragments = {
  field: `
    fragment masthead on masthead_MatrixField {
      ... on masthead_block_BlockType {
        id
        typeHandle
        idName
        backgroundImage {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
        }
        backgroundImageMobile {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
        }
        heading
        subheading
        buttonText
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
    }
  `,
}
