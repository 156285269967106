import { gql } from '@urql/core'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { defaultClient } from '../graphql/clients'
import {
  CContactForm,
  CContactFormBlock,
  CContactFormFields,
  CContactFormFragments,
} from '../components/organisms/c-contact-form'
import { CFootnoteHeading } from '../components/organisms/c-footnote-heading'
import {
  CFootnotes,
  CFootnotesBlock,
  CFootnotesFragments,
} from '../components/organisms/c-footnotes'
import {
  CIconWall,
  CIconWallBlock,
  CIconWallFragments,
} from '../components/organisms/c-icon-wall'
import {
  CImageCopy,
  CImageCopyBlock,
  CImageCopyFragments,
} from '../components/organisms/c-image-copy'
import {
  CImageCopyIcons,
  CImageCopyIconsBlock,
  CImageCopyIconsFragments,
} from '../components/organisms/c-image-copy-icons'
import {
  CMasthead,
  CMastheadBlock,
  CMastheadFragments,
} from '../components/organisms/c-masthead'
import {
  CMasthead2,
  CMastheadBlock2,
  CMastheadFragments2,
} from '../components/organisms/c-masthead2'
import {
  CTable,
  CTableBlock,
  CTableFragments,
} from '../components/organisms/c-table'
import DefaultLayout from '../layouts/default'
import { setCacheHeaders } from '../helpers/cache-config'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const { data } = await defaultClient.query(
      gql`
        query Entry($uri: [String]!) {
          entry(uri: $uri) {
            uri
            sectionHandle
            typeHandle
            title
            ... on homepage_homepage_Entry {
              masthead {
                ...masthead
              }
              imageCopy {
                ...imageCopy
              }
              iconWall {
                ...iconWall
              }
              table {
                ...table
              }
              table2 {
                ...table2
              }
              iconWall2 {
                ...iconWall2
              }
              imageCopyIcons {
                ...imageCopyIcons
              }
              cta {
                ...cta
              }
              footnotes {
                ...footnotes
              }
              masthead2 {
                ...masthead2
              }
            }
          }
          form(handle: "contactForm") {
            handle
            fields {
              ... on Field_Dropdown {
                handle
                name
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
                options {
                  label
                  value
                  isDefault
                }
              }
              ... on Field_SingleLineText {
                handle
                name
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
              }
              ... on Field_Agree {
                handle
                name
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
                description
              }
              ... on Field_Phone {
                handle
                name
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
              }
              ... on Field_Email {
                handle
                name
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
                validateDomain
              }
              ... on Field_Name {
                name
                handle
                required
                placeholder
                enableConditions
                conditions
                typeName
                errorMessage
                useMultipleFields
                firstNameEnabled
                firstNameLabel
                firstNamePlaceholder
                firstNameRequired
                firstNameErrorMessage
                lastNameEnabled
                lastNameLabel
                lastNamePlaceholder
                lastNameRequired
                lastNameErrorMessage
              }
            }
          }
        }
        ${CMastheadFragments.field}
        ${CImageCopyFragments.field}
        ${CIconWallFragments.field}
        ${CTableFragments.field}
        ${CTableFragments.field2}
        ${CIconWallFragments.field2}
        ${CFootnotesFragments.field}
        ${CContactFormFragments.field}
        ${CImageCopyIconsFragments.field}
        ${CMastheadFragments2.field}
      `,
      {
        uri: '__home__',
      }
    )

    if (!data.entry) {
      return {
        notFound: true,
      }
    }

    setCacheHeaders(context.res)

    return {
      props: {
        entry: data.entry,
        form: data.form,
      },
    }
  } catch (error) {
    return {
      notFound: true,
    }
  }
}

type Props = {
  entry: {
    masthead: Array<CMastheadBlock>
    masthead2: Array<CMastheadBlock2>
    imageCopy: Array<CImageCopyBlock>
    iconWall: Array<CIconWallBlock>
    iconWall2: Array<CIconWallBlock>
    table: Array<CTableBlock>
    table2: Array<CTableBlock>
    footnotes: Array<CFootnotesBlock>
    cta: Array<CContactFormBlock>
    imageCopyIcons: Array<CImageCopyIconsBlock>
  }
  form: CContactFormFields
}

export default function Index({ entry, form }: Props) {
  return (
    <DefaultLayout>
      <Head>
        <title>Staar.com</title>
      </Head>
      <CMasthead field={entry.masthead} />
      <CMasthead2 field={entry.masthead2} />
      <CImageCopy field={entry.imageCopy} />
      <CIconWall field={entry.iconWall} />
      <CTable field={entry.table} />
      <CTable field={entry.table2} />
      <CIconWall field={entry.iconWall2} />
      <CImageCopyIcons field={entry.imageCopyIcons} />
      <CContactForm field={entry.cta} formFields={form.fields} />
      <CFootnoteHeading field={entry.footnotes} />
      <CFootnotes field={entry.footnotes} />
    </DefaultLayout>
  )
}
