import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import css from 'styled-jsx/css'

export type CImageCopyBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  image?: Array<CraftAssetField>
  heading?: string
  listHeading?: string
  listItems?: Array<{
    item: string
  }>
}

type Props = {
  field: Array<CImageCopyBlock>
}

export const CImageCopy = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  const content = useMemo(() => {
    return field ? field[0] : null
  }, [field])

  useEffect(() => {
    if (sectionRef.current) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          onEnter: () => {
            setAnimate(true)
          },
        },
      })
    }
  }, [])

  if (!content) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      id={content.idName}
      ref={sectionRef}
      className="c-image-copy p-6 flex flex-col lg:flex-row scroll-mt-[90px]"
    >
      <div className="w-full lg:w-1/2 mb-3 lg:mb-0 lg:mr-3 bg-primary-teal">
        <div className="c-image-copy__ratio">
          {!!content.image?.length && (
            <img
              src={content.image[0].url}
              alt=""
              className={classNames(
                'lg:absolute lg:top-0 lg:left-0 w-full lg:h-full lg:object-contain transition-opacity duration-500 ease-out',
                {
                  'opacity-0': !animate,
                  'opacity-100': animate,
                }
              )}
            />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2 mt-3 lg:mt-0 lg:ml-3 bg-[#F7F7F7] p-6 lg:p-0">
        <div
          className={classNames(
            'c-image-copy__ratio transition-all transform duration-300 ease-out delay-200',
            {
              'opacity-0 translate-y-24': !animate,
              'opacity-100 translate-y-0': animate,
            }
          )}
        >
          <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-full lg:max-w-[371px]">
            {content.heading && (
              <h2
                className="c-image-copy__heading text-h6 text-neutral-dark-gray font-light"
                dangerouslySetInnerHTML={{ __html: content.heading }}
              />
            )}
            {!!content.listItems?.length && (
              <div className="mt-4 pl-2">
                {content.listHeading && (
                  <h3
                    className="text-p-lg text-neutral-dark-gray"
                    dangerouslySetInnerHTML={{ __html: content.listHeading }}
                  />
                )}
                {!!content.listItems.length && (
                  <ul className="list-disc list-outside pl-6">
                    {content.listItems.map((item, n) => {
                      return (
                        <li
                          key={n}
                          className="mt-4 text-p text-neutral-dark-gray"
                          dangerouslySetInnerHTML={{ __html: item.item }}
                        />
                      )
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  @media (min-width: 1024px) {
    .c-image-copy__ratio {
      position: relative;
      height: 0;
      padding-top: 100%;
    }
  }

  .c-image-copy__heading sup {
    font-size: 50%;
  }
`

export const CImageCopyFragments = {
  field: `
    fragment imageCopy on imageCopy_MatrixField {
      ... on imageCopy_block_BlockType {
        id
        typeHandle
        idName
        image {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
        }
        heading
        listHeading
        listItems {
          item
        }
      }
    }
  `,
}
