import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { MVideoModal } from 'ui'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

export interface CImageCopyIconsBlock {
  id?: string
  typeHandle?: string
  idName?: string
  image?: Array<CraftAssetField>
  heading?: string
  buttonText?: string
  video?: Array<CraftEmbeddedAssetField>
  icon?: Array<CraftAssetField>
  label?: string
}

type Props = {
  field: Array<CImageCopyIconsBlock>
}

export const CImageCopyIcons = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  interface Content extends CImageCopyIconsBlock {
    items: CImageCopyIconsBlock[]
  }

  const content = useMemo(() => {
    if (field) {
      let content: Content = {
        items: [],
      }

      field.forEach((block) => {
        if (block.typeHandle === 'main') {
          content = {
            ...content,
            ...block,
          }
        } else if (block.typeHandle === 'item') {
          content.items.push(block)
        }
      })

      return content
    } else {
      return null
    }
  }, [field])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (sectionRef.current) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          onEnter: () => {
            setAnimate(true)
          },
        },
      })
    }
  }, [])

  if (!content) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      id={content.idName}
      ref={sectionRef}
      className="c-image-copy-icons flex flex-col lg:flex-row bg-[#282829]"
    >
      <div className="w-full lg:w-1/2 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/3 2xl:w-1/2">
          {!!content.image?.length && (
            <img
              className="w-full h-[300px] lg:h-full object-cover"
              src={content.image[0].url}
              alt=""
            />
          )}
        </div>
        <div className="pt-14 w-full font-light px-6 lg:px-0 lg:pt-0 lg:w-2/3 lg:flex lg:flex-col lg:items-center lg:justify-center 2xl:w-1/2">
          <div className="flex flex-col justify-center">
            {content.heading && (
              <h2
                className={classNames(
                  'text-h6 lg:text-h5 text-white transform transition-all duration-300 ease-out',
                  {
                    'translate-y-16 opacity-0': !animate,
                    'translate-y-0 opacity-100': animate,
                  }
                )}
                dangerouslySetInnerHTML={{ __html: content.heading ?? '' }}
              />
            )}
            {content.buttonText && (
              <button
                className="rounded-[56px] self-start text-button font-bold text-primary-teal border-2 border-primary-teal uppercase px-6 py-2 sm:px-8 mt-4 sm:mt-6 hover:bg-white sm:hover:bg-primary-teal hover:text-neutral-dark-gray sm:hover:text-white transition-all duration-200 ease-out"
                onClick={openModal}
              >
                {content.buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="mr-auto px-6 lg:pl-10 lg:pr-6 lg:max-w-[612px] w-full lg:w-1/2 flex flex-col justify-center pt-9 pb-20 lg:py-36">
        {content.items.map((item, n) => {
          return (
            <div
              key={n}
              className={classNames(
                'c-image-copy-icons__item flex items-center mt-14 first:mt-0 transform transition-all duration-300 ease-out',
                {
                  'translate-y-16 opacity-0': !animate,
                  'translate-y-0 opacity-100': animate,
                }
              )}
            >
              <div className="w-[84px] flex justify-center flex-shrink-0">
                {!!item.icon?.length && (
                  <img className="max-w-full" src={item.icon[0].url} alt="" />
                )}
              </div>
              <h3
                className="pl-10 text-white text-p lg:text-p-lg max-w-[330px]"
                dangerouslySetInnerHTML={{ __html: item.label ?? '' }}
              />
            </div>
          )
        })}
      </div>
      {content.video &&
        content.video[0] != null &&
        content.video[0].embeddedAsset != null &&
        content.video[0].embeddedAsset.url && (
          <MVideoModal
            v-if="content.video[0] != null && content.video[0].embeddedAsset != null && content.video[0].embeddedAsset.url"
            open={modalOpen}
            video={content.video[0].embeddedAsset.url}
            onClose={closeModal}
          />
        )}
    </section>
  )
}

export const CImageCopyIconsFragments = {
  field: `
    fragment imageCopyIcons on imageCopyIcons_MatrixField {
      ... on imageCopyIcons_main_BlockType {
        id
        typeHandle
        idName
        image {
          url @transform(mode: "crop", width: 550, immediately: true)
          title
        }
        heading
        buttonText
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
      ... on imageCopyIcons_item_BlockType {
        id
        typeHandle
        icon {
          url @transform(mode: "crop", width: 120, immediately: true)
          title
        }
        label
      }
    }
  `,
}
