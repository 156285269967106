import { useMemo, useRef } from 'react'
import { CraftAssetField } from 'tsconfig/craft-types'
import { MFormieForm } from '../molecules/m-formie-form'

export type CContactFormBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  backgroundImage?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  heading?: string
}

export type CContactFormFields = {
  handle: string
  fields: Array<{
    handle?: string
    name?: string
    required?: boolean
    placeholder?: string
    enableConditions?: boolean
    conditions?: string
    typeName?: string
    errorMessage?: string
    options?: Array<{
      label?: string
      value?: string
      isDefault?: boolean
    }>
    description?: string
    validateDomain?: boolean
    useMultipleFields?: boolean
    firstNameEnabled?: string
    firstNameLabel?: string
    firstNamePlaceholder?: string
    firstNameRequired?: string
    firstNameErrorMessage?: string
    lastNameEnabled?: string
    lastNameLabel?: string
    lastNamePlaceholder?: string
    lastNameRequired?: string
    lastNameErrorMessage?: string
  }>
}

type Props = {
  field: Array<CContactFormBlock>
  formFields: CContactFormFields['fields']
}

export const CContactForm = ({ field, formFields }: Props) => {
  const sectionRef = useRef<HTMLDivElement>(null)

  const content = useMemo(() => {
    return field ? field[0] : null
  }, [field])

  if (!content) {
    return <></>
  }

  return (
    <section
      ref={sectionRef}
      className="c-cta relative flex items-center overflow-hidden py-8 bg-neutral-light-gray"
    >
      <span id="contact-us" className="absolute top-0 -mt-[95px]" />
      <div className="px-8 lg:px-18 w-full z-10 flex flex-col items-center py-8">
        {content.heading && (
          <h2 className="text-h6 md:text-h5 text-neutral-dark-gray text-center max-w-[702px] font-light">
            {content.heading}
          </h2>
        )}
        <MFormieForm fields={formFields} />
      </div>
    </section>
  )
}

export const CContactFormFragments = {
  field: `
    fragment cta on cta_MatrixField {
      ... on cta_block_BlockType {
        id
        typeHandle
        idName
        backgroundImage {
          url @transform(mode: "crop", width: 2000, immediately: true)
          title
        }
        backgroundImageMobile {
          url @transform(mode: "crop", width: 640, immediately: true)
          title
        }
        heading
      }
    }
  `,
}
