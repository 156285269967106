import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import css from 'styled-jsx/css'

export interface CIconWallBlock {
  id?: string
  typeHandle?: string
  idName?: string
  heading?: string
  theme?: string
  layout?: string
  labelSize?: string
  label?: string
  icon?: Array<{
    url: string
    title: string
  }>
}

type Props = {
  field: Array<CIconWallBlock>
}

export const CIconWall = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  interface Content extends CIconWallBlock {
    items: CIconWallBlock[]
  }

  const content = useMemo(() => {
    if (field) {
      let content: Content = {
        items: [],
      }

      field.forEach((block) => {
        if (block.typeHandle === 'main') {
          content = {
            ...content,
            ...block,
          }
        } else if (block.typeHandle === 'item') {
          content.items.push(block)
        }
      })

      return content
    } else {
      return null
    }
  }, [field])

  useEffect(() => {
    if (sectionRef.current) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          onEnter: () => {
            setAnimate(true)
          },
        },
      })
    }
  })

  if (!content) {
    return <></>
  }

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      ref={sectionRef}
      className={classNames('c-icon-wall py-14 lg:py-40', {
        'bg-[#282829] text-white': content.theme === 'dark',
        'bg-[#F7F7F7] text-neutral-dark-gray': content.theme === 'light',
        'c-icon-wall--layout-header-top': content.layout === 'headingTop',
        'c-icon-wall--layout-header-left': content.layout === 'headingLeft',
      })}
    >
      <div
        className={classNames('flex o-container o-container--xl', {
          'flex-col lg:flex-row lg:items-center':
            content.layout === 'headingLeft',
          'flex-col': content.layout === 'headingTop',
        })}
      >
        <div
          className={classNames('w-full', {
            'lg:w-1/3 lg:flex-shrink-0 lg:mr-16':
              content.layout === 'headingLeft',
          })}
        >
          {content.heading && (
            <h2
              className={classNames(
                'font-light transition-all duration-500 ease-out transform text-h6 lg:text-h5',
                {
                  'lg:text-center': content.layout === 'headingTop',
                  'opacity-0 translate-y-32': !animate,
                  'opacity-100 translate-y-0': animate,
                }
              )}
              dangerouslySetInnerHTML={{ __html: content.heading ?? '' }}
            />
          )}
        </div>
        <div
          className={classNames(
            'flex flex-wrap c-icon-wall__items-container lg:flex-grow',
            {
              'mt-10 lg:mt-0': content.layout === 'headingLeft',
              'mt-10 lg:mt-24': content.layout === 'headingTop',
              animate,
            }
          )}
        >
          {content.items.map((item, n) => {
            return (
              <div
                key={n}
                className={classNames('flex items-center c-icon-wall__item', {
                  'w-full lg:w-1/2': content.layout === 'headingTop',
                  'flex-col lg:flex-row w-1/2':
                    content.layout === 'headingLeft',
                })}
              >
                <div
                  className={classNames(
                    'h-[68px] w-full lg:h-auto lg:w-[120px] flex justify-center flex-shrink-0',
                    {
                      'w-[90px]': content.layout === 'headingTop',
                      'lg:mr-6': content.layout === 'headingLeft',
                      'mr-8 lg:mr-6': content.layout === 'headingTop',
                    }
                  )}
                >
                  {!!item.icon?.length && (
                    <img
                      v-if="item.icon.length"
                      className="w-full"
                      src={item.icon[0].url}
                      alt=""
                    />
                  )}
                </div>
                <div
                  className={classNames('flex-grow mt-4 text-p lg:mt-0', {
                    'lg:text-p-lg': content.labelSize === 'large',
                    'text-center lg:text-left':
                      content.layout === 'headingLeft',
                    'text-left': content.layout === 'headingTop',
                  })}
                  dangerouslySetInnerHTML={{ __html: item.label ?? '' }}
                />
              </div>
            )
          })}
        </div>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-icon-wall__item {
    margin-bottom: 4rem;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }

  .c-icon-wall__item:nth-child(1) {
    transition-delay: 0.2s;
  }
  .c-icon-wall__item:nth-child(2) {
    transition-delay: 0.4s;
  }
  .c-icon-wall__item:nth-child(3) {
    transition-delay: 0.6s;
  }
  .c-icon-wall__item:nth-child(4) {
    transition-delay: 0.8s;
  }
  .c-icon-wall__item:nth-child(5) {
    transition-delay: 1s;
  }
  .c-icon-wall__item:nth-child(6) {
    transition-delay: 1.2s;
  }

  .c-icon-wall__item:nth-child(odd) {
    padding-right: 1.25rem;
  }

  .c-icon-wall__item:nth-child(even) {
    padding-left: 1.25rem;
  }

  .c-icon-wall--layout-header-left .c-icon-wall__item:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .c-icon-wall--layout-header-top .c-icon-wall__item:nth-child(odd),
  .c-icon-wall--layout-header-top .c-icon-wall__item:nth-child(even) {
    padding: 0;
  }

  @media (min-width: 1024px) {
    .c-icon-wall--layout-header-top .c-icon-wall__item:nth-child(odd) {
      padding-right: 8rem;
    }

    .c-icon-wall--layout-header-top .c-icon-wall__item:nth-child(even) {
      padding-left: 8rem;
    }
  }

  .c-icon-wall__items-container.animate .c-icon-wall__item {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const CIconWallFragments = {
  field: `
    fragment iconWall on iconWall_MatrixField {
      ... on iconWall_main_BlockType {
        id
        typeHandle
        idName
        heading
        theme
        layout
        labelSize
      }
      ... on iconWall_item_BlockType {
        id
        typeHandle
        label
        icon {
          url
          title
        }
      }
    }
  `,
  field2: `
    fragment iconWall2 on iconWall2_MatrixField {
      ... on iconWall2_main_BlockType {
        id
        typeHandle
        idName
        heading
        theme
        layout
        labelSize
      }
      ... on iconWall2_item_BlockType {
        id
        typeHandle
        label
        icon {
          url
          title
        }
      }
    }
  `,
}
