import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftAssetField, CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { MVideoModal } from 'ui'

export type CMastheadBlock2 = {
  id?: string
  typeHandle?: string
  idName?: string
  backgroundImage?: Array<CraftAssetField>
  backgroundImageTablet?: Array<CraftAssetField>
  backgroundImageMobile?: Array<CraftAssetField>
  heading?: string
  subheading?: string
  buttonText?: string
  buttonText2?: string
  video?: Array<CraftEmbeddedAssetField>
}

type Props = {
  field: Array<CMastheadBlock2>
}

export const CMasthead2 = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const sectionRef = useRef<HTMLDivElement>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [windowWidth, setWindowWidth] = useState(0)

  const content = useMemo(() => {
    return field ? field[0] : null
  }, [field])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (sectionRef.current) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          onEnter: () => {
            setAnimate(true)
          },
        },
      })
    }
  })

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!content) {
    return <></>
  }

  const headingFormatted = content?.heading?.replace(
    /\\n|\\r\\n|\\r|\n|\r/g,
    windowWidth > 600 ? '<br/>' : ' '
  )

  const subheadingFormatted = content?.subheading?.replace(
    /\\n|\\r\\n|\\r|\n|\r/g,
    windowWidth > 450 ? '<br/>' : ' '
  )

  /* eslint-disable @next/next/no-img-element */
  return (
    <section
      id={content.idName}
      ref={sectionRef}
      className={classNames(
        'bg-[#F0F3F4] sm:bg-[linear-gradient(270deg, #F0F3F4 50.52%, rgba(240, 243, 244, 0.00) 100%)] pt-[2rem] h-[auto] sm:h-[auto] lg:h-[auto] scroll-mt-[90px]'
      )}
    >
      <div className="flex items-center justify-between flex-col o-container o-container--lg z-10 pb-[44px] h-[100%] transition-all transform duration-300 ease-out">
        {content.heading && (
          <h1
            className={classNames(
              'capitalize text-center transition-all duration-300 ease-out text-h7 transform sm:text-h5 lg:text-h4',
              {
                'opacity-0 translate-y-16': !animate,
                'opacity-100 translate-y-0': animate,
              }
            )}
            style={{
              color: '#282829',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '115%',
            }}
            dangerouslySetInnerHTML={{
              __html: headingFormatted ?? '',
            }}
          />
        )}
        <div className="absolute"></div>
        <div>
          <picture>
            {/* Tablet Breakpoint */}
            {!!content.backgroundImageTablet?.length && (
              <source
                media="(min-width: 641px) and (max-width: 1024px)"
                srcSet={content.backgroundImageTablet[0].url}
              />
            )}
            {/* Mobile Breakpoint */}
            {!!content.backgroundImageMobile?.length && (
              <source
                media="(max-width: 640px)"
                srcSet={content.backgroundImageMobile[0].url}
              />
            )}
            {/* Default */}
            {!!content.backgroundImage?.length && (
              <img
                className={classNames(
                  'object-cover object-center h-auto py-5 sm:py-10',
                  {
                    'opacity-0 translate-y-16': !animate,
                    'opacity-100 translate-y-0': animate,
                  }
                )}
                src={content.backgroundImage[0].url}
                alt=""
              />
            )}
          </picture>
        </div>
        <div className={classNames('w-full')}>
          {content.subheading && (
            <h2
              className={classNames(
                'text-center transition-all duration-300 ease-out delay-200 transform text-h8 sm:text-h7',
                {
                  'opacity-0 translate-y-16': !animate,
                  'opacity-100 translate-y-0': animate,
                }
              )}
              style={{
                color: '#282829',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '0.8px',
                whiteSpace: 'pre-line',
              }}
              dangerouslySetInnerHTML={{
                __html: subheadingFormatted ?? '',
              }}
            />
          )}
          <div
            className={classNames(
              'flex justify-center gap-3 w-full transition-all duration-300 ease-out delay-500 transform mt-8 flex-col sm:flex-row sm-gap-16',
              {
                'opacity-0 translate-y-16': !animate,
                'opacity-100 translate-y-0': animate,
              }
            )}
          >
            {content.buttonText && (
              <a
                href="/#contact-us"
                className="rounded-[56px] text-center text-button font-bold text-primary-teal border-2 border-primary-teal uppercase border-transparent bg-primary-teal text-neutral-white hover:bg-primary-bright-teal hover:bg-primary-teal hover:text-neutral-white transition-all duration-200 ease-out"
                style={{
                  padding: '15px 20px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  letterSpacing: '1.12px',
                }}
              >
                {content.buttonText}
              </a>
            )}
            {content.buttonText2 && (
              <button
                className="rounded-[56px] text-button font-bold text-primary-teal border-2 border-primary-teal uppercase bg-white hover:bg-white sm:hover:bg-primary-teal hover:text-neutral-dark-gray sm:hover:text-white transition-all duration-200 ease-out"
                style={{
                  padding: '15px 20px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  letterSpacing: '1.12px',
                }}
                onClick={openModal}
              >
                {content.buttonText2}
              </button>
            )}
          </div>
        </div>
      </div>
      {content?.video && content.video[0] && (
        <MVideoModal
          open={modalOpen}
          video={content.video[0].embeddedAsset?.url}
          onClose={closeModal}
        />
      )}
    </section>
  )
}

export const CMastheadFragments2 = {
  field: `
    fragment masthead2 on masthead2_MatrixField {
      ... on masthead2_block_BlockType {
        id
        typeHandle
        idName
        backgroundImage {
          url
          title
        }
        backgroundImageTablet {
          url
          title
        }
        backgroundImageMobile {
          url
          title
        }
        heading
        subheading
        buttonText
        buttonText2
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
    }
  `,
}
