type Props = {
  onClose: () => void
}

export const MFormModal = ({ onClose }: Props) => {
  const handleClose = () => {
    if (onClose instanceof Function) {
      onClose()
    }
  }

  return (
    <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
      <div className="bg-white rounded max-w-md p-16 text-center">
        <h1 className="text-primary-dark-teal text-4xl mb-8">Success!</h1>
        <p className="mb-8">
          Thanks for reaching out! We will be in touch very soon.
        </p>
        <button
          className="bg-primary-teal text-neutral-white hover:bg-primary-bright-teal cursor-pointer font-bold text-center uppercase transition-all duration-300 text-button align-middle border-2 border-transparent px-6 py-2 rounded-full"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </div>
  )
}
