import classNames from 'classnames'
import { useMemo } from 'react'
import css from 'styled-jsx/css'
import { CFootnotesBlock } from './c-footnotes'

type Props = {
  field: Array<CFootnotesBlock>
}

export const CFootnoteHeading = ({ field }: Props) => {
  const content = useMemo(() => {
    return field ?? null
  }, [field])

  if (!content) {
    return <></>
  }

  return (
    <section className="pt-6 pb-10 c-footnotes md:py-14">
      <div className="o-container o-container--lg">
        {field.map((section, n) => {
          return (
            <div
              key={n}
              className={classNames({
                hidden: section.typeHandle !== 'single',
              })}
            >
              {section.typeHandle === 'single' && (
                <h2 className="font-light text-p-lg text-neutral-dark-gray">
                  {section.heading}
                </h2>
              )}
            </div>
          )
        })}
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-footnotes__list li::marker {
    font-weight: bold;
  }

  .c-footnotes__copy p {
    margin-top: 1.25rem;
  }

  .c-footnotes__copy p:first-of-type {
    margin-top: 0;
  }
`
