import { gql } from '@urql/core'
import { FormEvent, useMemo, useRef, useState } from 'react'
import { AButton, ACheckbox, AInput, ASelect } from 'ui'
import TagManager from 'react-gtm-module'
import { defaultClient } from '../../graphql/clients'
import { CContactFormFields } from '../organisms/c-contact-form'
import { MFormModal } from './m-form-modal'

type Props = {
  fields: CContactFormFields['fields']
}

export const MFormieForm = ({ fields }: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false)

  const [form, setForm] = useState({
    whatBestDescribesYou: '',
    whatWouldYouLikeToDo: '',
    bestContactMethod: '',
  })

  const [errors, setErrors] = useState({
    whatBestDescribesYou: false,
    bestDescribesYouOther: false,
    whatWouldYouLikeToDo: false,
    likeToDoOther: false,
    firstName: false,
    lastName: false,
    uSStateWhereYouLiveWork: false,
    bestContactMethod: false,
    phone: false,
    email: false,
    legalTerms: false,
  })

  const formRef = useRef<HTMLFormElement>(null)

  const formFields = useMemo(() => {
    if (fields) {
      const whatBestDescribesYouFields = fields.find(
        (f) => f.handle === 'whatBestDescribesYou'
      )
      const bestDescribesYouOtherFields = fields.find(
        (f) => f.handle === 'bestDescribesYouOther'
      )

      const whatWouldYouLikeToDoFields = fields.find(
        (f) => f.handle === 'whatWouldYouLikeToDo'
      )
      const likeToDoOtherFields = fields.find(
        (f) => f.handle === 'likeToDoOther'
      )

      const firstNameFields = fields.find((f) => f.handle === 'firstName')
      const lastNameFields = fields.find((f) => f.handle === 'lastName')

      const uSStateWhereYouLiveWorkFields = fields.find(
        (f) => f.handle === 'uSStateWhereYouLiveWork'
      )

      const bestContactMethodFields = fields.find(
        (f) => f.handle === 'bestContactMethod'
      )
      const phoneFields = fields.find((f) => f.handle === 'phone')
      const emailFields = fields.find((f) => f.handle === 'email')

      const legalTermsFormFields = fields.find((f) => f.handle === 'legalTerms')
      const legalTermsParsed = JSON.parse(
        legalTermsFormFields?.description ?? '{}'
      )
      const legalText = legalTermsParsed.length
        ? legalTermsParsed[0].content[0].text
        : ''

      const legalTermsFields = {
        ...legalTermsFormFields,
        text: legalText,
      }

      return {
        whatBestDescribesYou: whatBestDescribesYouFields,
        bestDescribesYouOther: bestDescribesYouOtherFields,
        whatWouldYouLikeToDo: whatWouldYouLikeToDoFields,
        likeToDoOther: likeToDoOtherFields,
        firstName: firstNameFields,
        lastName: lastNameFields,
        uSStateWhereYouLiveWork: uSStateWhereYouLiveWorkFields,
        bestContactMethod: bestContactMethodFields,
        phone: phoneFields,
        email: emailFields,
        legalTerms: legalTermsFields,
      }
    } else {
      return null
    }
  }, [fields])

  const showModal = () => {
    setIsShowingModal(true)
  }

  const closeModal = () => {
    setIsShowingModal(false)
  }

  const resetForm = () => {
    setErrors({
      whatBestDescribesYou: false,
      bestDescribesYouOther: false,
      whatWouldYouLikeToDo: false,
      likeToDoOther: false,
      firstName: false,
      lastName: false,
      uSStateWhereYouLiveWork: false,
      bestContactMethod: false,
      phone: false,
      email: false,
      legalTerms: false,
    })

    setForm({
      whatBestDescribesYou: '',
      whatWouldYouLikeToDo: '',
      bestContactMethod: '',
    })

    if (formRef.current) {
      formRef.current.reset()
    }
  }

  const onSuccess = (response: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent_formsubmit',
        eventCategory: 'Form',
        eventAction: 'Success',
        whatBestDescribesYou:
          formFields?.whatBestDescribesYou?.options?.find(
            (v) => v.value === form.whatBestDescribesYou
          )?.label ?? '',
        whatWouldYouLikeToDo:
          formFields?.whatWouldYouLikeToDo?.options?.find(
            (v) => v.value === form.whatWouldYouLikeToDo
          )?.label ?? '',
        bestContactMethod:
          formFields?.bestContactMethod?.options?.find(
            (v) => v.value === form.bestContactMethod
          )?.label ?? '',
      },
    })

    showModal()
    resetForm()
  }

  const onError = (error: any) => {
    console.error(error)
    const gqlErrors = JSON.parse(error.graphQLErrors[0].message)

    let newErrors = {
      whatBestDescribesYou: false,
      bestDescribesYouOther: false,
      whatWouldYouLikeToDo: false,
      likeToDoOther: false,
      firstName: false,
      lastName: false,
      uSStateWhereYouLiveWork: false,
      bestContactMethod: false,
      phone: false,
      email: false,
      legalTerms: false,
    }

    for (const err of Object.keys(gqlErrors)) {
      newErrors = { ...newErrors, [err]: true }
    }

    setErrors(newErrors)

    if (window.newrelic) {
      window.newrelic.noticeError('contactFormSubmissionError', gqlErrors)
    }
  }

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (!formRef.current) {
      return
    }

    const formData = new FormData(formRef.current)
    const formEntries = Object.fromEntries(formData.entries())

    setLoading(true)

    if (window.newrelic) {
      window.newrelic.addPageAction('contactFormSubmitted', {
        state: formEntries.uSStateWhereYouLiveWork?.toString() ?? undefined,
        bestContactMethod:
          formEntries.bestContactMethod?.toString() ?? undefined,
      })
    }

    const gqlVariables = {
      whatBestDescribesYou: formEntries.whatBestDescribesYou?.toString(),
      bestDescribesYouOther: formEntries.bestDescribesYouOther?.toString(),
      whatWouldYouLikeToDo: formEntries.whatWouldYouLikeToDo?.toString(),
      likeToDoOther: formEntries.likeToDoOther?.toString(),
      firstName: formEntries.firstName?.toString(),
      lastName: formEntries.lastName?.toString(),
      uSStateWhereYouLiveWork: formEntries.uSStateWhereYouLiveWork?.toString(),
      bestContactMethod: formEntries.bestContactMethod?.toString(),
      phone: formEntries.phone?.toString(),
      email: formEntries.email?.toString(),
      legalTerms: formEntries.legalTerms?.toString() ? 'Yes' : undefined,
    }

    const mutateContactForm = async (): Promise<any> => {
      return await defaultClient
        .mutation(
          gql`
            mutation SubmitForm(
              $whatBestDescribesYou: String
              $bestDescribesYouOther: String
              $whatWouldYouLikeToDo: String
              $likeToDoOther: String
              $firstName: String
              $lastName: String
              $uSStateWhereYouLiveWork: String
              $bestContactMethod: String
              $phone: String
              $email: String
              $legalTerms: String
            ) {
              save_contactForm_Submission(
                whatBestDescribesYou: $whatBestDescribesYou
                bestDescribesYouOther: $bestDescribesYouOther
                whatWouldYouLikeToDo: $whatWouldYouLikeToDo
                likeToDoOther: $likeToDoOther
                firstName: $firstName
                lastName: $lastName
                uSStateWhereYouLiveWork: $uSStateWhereYouLiveWork
                bestContactMethod: $bestContactMethod
                phone: $phone
                email: $email
                legalTerms: $legalTerms
              ) {
                id
                dateCreated
                statusId
              }
            }
          `,
          gqlVariables
        )
        .toPromise()
    }

    mutateContactForm()
      .then(({ data }) => {
        setLoading(false)
        const response = data.save_contactForm_Submission
        onSuccess(response)
      })
      .catch((error) => {
        setLoading(false)
        onError(error)
      })
  }

  if (!formFields) {
    return <></>
  }

  return (
    <div>
      <form
        ref={formRef}
        noValidate
        className="flex flex-col max-w-3xl gap-8 mt-8 transition-opacity duration-300 ease-out"
        onSubmit={(e) => handleFormSubmit(e)}
      >
        <div>
          <label
            htmlFor={formFields.whatBestDescribesYou?.handle}
            className="uppercase text-neutral-dark-gray text-button"
          >
            {formFields.whatBestDescribesYou?.required && <span>*</span>}
            {formFields.whatBestDescribesYou?.name}
          </label>
          <ASelect
            id={formFields.whatBestDescribesYou?.handle}
            value={form.whatBestDescribesYou}
            onChangeHandler={(value) =>
              setForm({ ...form, whatBestDescribesYou: value })
            }
            name={formFields.whatBestDescribesYou?.handle}
            options={
              formFields.whatBestDescribesYou?.options?.map((option) => {
                return {
                  label: option.label ?? '',
                  value: option.value ?? '',
                }
              }) ?? []
            }
            required={formFields.whatBestDescribesYou?.required ?? false}
            invalid={errors.whatBestDescribesYou}
          />
          {form.whatBestDescribesYou === 'other' && (
            <div className="mt-2">
              <AInput
                id={formFields.bestDescribesYouOther?.handle}
                name={formFields.bestDescribesYouOther?.handle}
                placeholder={formFields.bestDescribesYouOther?.placeholder}
                required={formFields.bestDescribesYouOther?.required}
                invalid={errors.bestDescribesYouOther}
              />
            </div>
          )}
        </div>
        <div>
          <label
            htmlFor={formFields.whatWouldYouLikeToDo?.handle}
            className="uppercase text-neutral-dark-gray text-button"
          >
            {formFields.whatWouldYouLikeToDo?.required && <span>*</span>}
            {formFields.whatWouldYouLikeToDo?.name}
          </label>
          <ASelect
            id={formFields.whatWouldYouLikeToDo?.handle}
            value={form.whatWouldYouLikeToDo}
            onChangeHandler={(value) =>
              setForm({ ...form, whatWouldYouLikeToDo: value })
            }
            name={formFields.whatWouldYouLikeToDo?.handle}
            options={
              formFields.whatWouldYouLikeToDo?.options?.map((option) => {
                return {
                  label: option.label ?? '',
                  value: option.value ?? '',
                }
              }) ?? []
            }
            required={formFields.whatWouldYouLikeToDo?.required ?? false}
            invalid={errors.whatWouldYouLikeToDo}
          />
          {form.whatWouldYouLikeToDo === 'other' && (
            <div className="mt-2">
              <AInput
                id={formFields.likeToDoOther?.handle}
                name={formFields.likeToDoOther?.handle}
                placeholder={formFields.likeToDoOther?.placeholder}
                required={formFields.likeToDoOther?.required}
                invalid={errors.likeToDoOther}
              />
            </div>
          )}
        </div>
        <div className="flex gap-8">
          <div className="flex-grow">
            <label
              htmlFor={formFields.firstName?.handle}
              className="uppercase text-neutral-dark-gray text-button"
            >
              {formFields.firstName?.required && <span>*</span>}
              {formFields.firstName?.name}
            </label>
            <AInput
              id={formFields.firstName?.handle}
              name={formFields.firstName?.handle}
              placeholder={formFields.firstName?.placeholder}
              required={formFields.firstName?.required}
              invalid={errors.firstName}
            />
          </div>
          <div className="flex-grow">
            <label
              htmlFor={formFields.lastName?.handle}
              className="uppercase text-neutral-dark-gray text-button"
            >
              {formFields.lastName?.required && <span>*</span>}
              {formFields.lastName?.name}
            </label>
            <AInput
              id={formFields.lastName?.handle}
              name={formFields.lastName?.handle}
              placeholder={formFields.lastName?.placeholder}
              required={formFields.lastName?.required}
              invalid={errors.lastName}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="formFields.uSStateWhereYouLiveWork.handle"
            className="uppercase text-neutral-dark-gray text-button"
          >
            {formFields.uSStateWhereYouLiveWork?.required && <span>*</span>}
            {formFields.uSStateWhereYouLiveWork?.name}
          </label>
          <ASelect
            id={formFields.uSStateWhereYouLiveWork?.handle}
            name={formFields.uSStateWhereYouLiveWork?.handle}
            options={
              formFields.uSStateWhereYouLiveWork?.options?.map((option) => {
                return {
                  label: option.label ?? '',
                  value: option.value ?? '',
                }
              }) ?? []
            }
            required={formFields.uSStateWhereYouLiveWork?.required ?? false}
            invalid={errors.uSStateWhereYouLiveWork}
          />
        </div>
        <div>
          <label
            htmlFor={formFields.bestContactMethod?.handle}
            className="uppercase text-neutral-dark-gray text-button"
          >
            {formFields.bestContactMethod?.required && <span>*</span>}
            {formFields.bestContactMethod?.name}
          </label>
          <ASelect
            id={formFields.bestContactMethod?.handle}
            value={form.bestContactMethod}
            onChangeHandler={(value) =>
              setForm({ ...form, bestContactMethod: value })
            }
            name={formFields.bestContactMethod?.handle}
            options={
              formFields.bestContactMethod?.options?.map((option) => {
                return {
                  label: option.label ?? '',
                  value: option.value ?? '',
                }
              }) ?? []
            }
            required={formFields.bestContactMethod?.required ?? false}
            invalid={errors.bestContactMethod}
          />
          {form.bestContactMethod === 'phone' && (
            <div className="mt-8">
              <label
                htmlFor={formFields.phone?.handle}
                className="uppercase text-neutral-dark-gray text-button"
              >
                {formFields.phone?.required && <span>*</span>}
                {formFields.phone?.name}
              </label>
              <AInput
                id={formFields.phone?.handle}
                v-model="form[formFields.phone.handle]"
                name={formFields.phone?.handle}
                placeholder={formFields.phone?.placeholder}
                required={formFields.phone?.required}
                invalid={errors.phone}
              />
            </div>
          )}
          {form.bestContactMethod === 'email' && (
            <div className="mt-8">
              <label
                htmlFor="formFields.email.handle"
                className="uppercase text-neutral-dark-gray text-button"
              >
                <span v-if="formFields.email.required">*</span>
                {formFields.email?.name}
              </label>
              <AInput
                id={formFields.email?.handle}
                name={formFields.email?.handle}
                placeholder={formFields.email?.placeholder}
                required={formFields.email?.required}
                invalid={errors.email}
              />
            </div>
          )}
        </div>
        <div>
          <ACheckbox
            name={formFields.legalTerms.handle}
            label={formFields.legalTerms.text}
            invalid={errors.legalTerms}
          />
        </div>
        <div className="flex justify-start">
          {/* <p>Captcha goes here</p> */}
          <AButton type="submit" disabled={loading}>
            <span>Submit</span>
          </AButton>
        </div>
      </form>
      {isShowingModal && <MFormModal onClose={closeModal} />}
      <div className="max-w-[600px] overflow-auto"></div>
    </div>
  )
}
