import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CraftEmbeddedAssetField } from 'tsconfig/craft-types'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import css from 'styled-jsx/css'
import { ASvg, MVideoModal } from 'ui'
import SvgShieldIcon from 'ui/svg/icons/fill/shield.svg'
import SvgTableFeatureIcon from 'ui/svg/icons/fill/table-feature.svg'

export type CTableBlock = {
  id?: string
  typeHandle?: string
  idName?: string
  componentHeading?: string
  heading?: Array<{
    content?: string
    removeLeftBorder?: boolean
    removeRightBorder?: boolean
    transparent?: boolean
  }>
  copy?: string
  supText?: string
  additionalText?: string
  footnotes?: string
  buttonText?: string
  video?: Array<CraftEmbeddedAssetField>
  rows?: Array<{
    row: Array<{
      content?: string
      removeLeftBorder?: boolean
      removeRightBorder?: boolean
      featured?: boolean
    }>
  }>
}

type Props = {
  field: Array<CTableBlock>
}

export const CTable = ({ field }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  type Content = {
    idName?: string
    heading?: string
    copy?: string
    supText?: string
    additionalText?: string
    footnotes?: string
    buttonText?: string
    video?: Array<CraftEmbeddedAssetField>
    desktopTable?: {
      heading?: CTableBlock['heading']
      rows?: {
        content?: string
        removeLeftBorder?: boolean
        removeRightBorder?: boolean
        featured?: boolean
      }[][]
    }
    mobileTables: Array<{
      heading?: CTableBlock['heading']
      rows?: {
        content?: string
        removeLeftBorder?: boolean
        removeRightBorder?: boolean
        featured?: boolean
      }[][]
    }>
  }

  const content = useMemo(() => {
    if (field) {
      let data: Content = {
        mobileTables: [],
      }

      field.forEach((block) => {
        if (block.typeHandle === 'main') {
          data = {
            ...data,
            idName: block.idName,
            heading: block.componentHeading,
            copy: block.copy,
            supText: block.supText,
            additionalText: block.additionalText,
            footnotes: block.footnotes,
            buttonText: block.buttonText,
            video: block.video,
          }
        } else if (block.typeHandle === 'desktopTable') {
          data = {
            ...data,
            desktopTable: {
              heading: block.heading,
              rows: block.rows?.map((item) => {
                return item.row
              }),
            },
          }
        } else if (block.typeHandle === 'mobileTable') {
          data.mobileTables.push({
            heading: block.heading,
            rows: block.rows?.map((item) => {
              return item.row
            }),
          })
        }
      })

      return data
    } else {
      return null
    }
  }, [field])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (sectionRef.current) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top center',
          onEnter: () => {
            setAnimate(true)
          },
        },
      })
    }
  }, [])

  if (!field) {
    return <></>
  }

  return (
    <section
      id={content?.idName}
      ref={sectionRef}
      className="pb-12 c-table pt-9 md:p-0 md:my-32 scroll-mt-[100px]"
    >
      {content?.supText && (
        <div
          className={classNames(
            'flex mb-8 transition-opacity duration-200 ease-out o-container o-container--xl md:justify-end md:mb-4',
            {
              'opacity-0': !animate,
              'opacity-100': animate,
            }
          )}
        >
          <div
            className="text-p-lg text-neutral-dark-gray text-left md:text-right max-w-[600px]"
            dangerouslySetInnerHTML={{ __html: content.supText }}
          />
        </div>
      )}
      <div className="flex flex-col o-container o-container--xl md:flex-row">
        <div
          className={classNames(
            'flex flex-col flex-shrink-0 md:w-[287px] md:mr-6 transform duration-300 ease-out transition-all',
            {
              'opacity-0 translate-y-24': !animate,
              'opacity-100 translate-y-0': animate,
            }
          )}
        >
          <ASvg svg={SvgShieldIcon} />
          {content?.heading && (
            <h2 className="mt-3 font-light text-h6 text-neutral-dark-gray">
              {content?.heading}
            </h2>
          )}
          {content?.copy && (
            <p
              className="text-p text-neutral-dark-gray mt-3 md:max-w-[239px]"
              dangerouslySetInnerHTML={{ __html: content.copy }}
            />
          )}
          {content?.buttonText && (
            <button
              className="self-start rounded-[56px] text-button font-bold text-primary-teal border-2 border-primary-teal uppercase px-6 py-2 sm:px-8 mt-4 sm:mt-6 hover:bg-white sm:hover:bg-primary-teal hover:text-neutral-dark-gray sm:hover:text-white transition-all duration-200 ease-out"
              onClick={openModal}
            >
              {content?.buttonText}
            </button>
          )}
        </div>
        <div
          className={classNames(
            'flex-grow transition-all duration-500 ease-out delay-300 transform',
            {
              'opacity-0 translate-y-24': !animate,
              'opacity-100 translate-y-0': animate,
            }
          )}
        >
          <div className="overflow-hidden rounded-[8px] hidden md:block">
            {content?.desktopTable && (
              <table className="c-table__table text-p text-center w-full">
                <tbody>
                  <tr>
                    {content?.desktopTable.heading?.map((tableField, n) => {
                      return (
                        <th
                          key={n}
                          className={classNames(
                            'bg-primary-dark-teal text-white font-semibold relative px-2 py-5 align-middle border border-white w-1/2 md:w-auto',
                            {
                              'no-right-border': tableField.removeRightBorder,
                              'no-left-border': tableField.removeLeftBorder,
                              transparent: tableField.transparent,
                              rounded:
                                n > 0 &&
                                content.desktopTable?.heading &&
                                content.desktopTable?.heading[n - 1]
                                  .transparent,
                            }
                          )}
                        >
                          {tableField.content}
                        </th>
                      )
                    })}
                  </tr>
                  {content.desktopTable?.rows?.map((row, n) => {
                    return (
                      <tr key={n}>
                        {row.map((tableField, m) => {
                          return (
                            <td
                              key={m}
                              className={classNames(
                                'bg-neutral-light-gray text-neutral-dark-gray font-medium relative px-2 py-5 align-middle border border-white w-1/2 md:w-auto',
                                {
                                  'no-right-border':
                                    tableField.removeRightBorder,
                                  'no-left-border': tableField.removeLeftBorder,
                                  feature: tableField.featured,
                                  rounded:
                                    m === 0 &&
                                    content.desktopTable?.heading &&
                                    content.desktopTable?.heading[0]
                                      .transparent,
                                }
                              )}
                            >
                              {tableField.featured && (
                                <ASvg
                                  svg={SvgTableFeatureIcon}
                                  className="absolute top-0 left-0"
                                />
                              )}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tableField.content ?? '',
                                }}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
          {content?.mobileTables && (
            <div
              className={classNames(
                'transition-all duration-500 ease-out delay-300 transform',
                {
                  'opacity-0 translate-y-24': !animate,
                  'opacity-100 translate-y-0': animate,
                }
              )}
            >
              {content.mobileTables.map((table, n) => {
                return (
                  <div
                    key={n}
                    className="c-table__table-container overflow-hidden rounded-[8px] md:hidden mt-6 md:mt-0"
                  >
                    <table className="c-table__table text-p text-center w-full">
                      <tbody>
                        <tr>
                          {table.heading?.map((tableField, m) => {
                            return (
                              <th
                                key={m}
                                className={classNames(
                                  'bg-primary-dark-teal text-white font-semibold relative px-2 py-5 align-middle border border-white w-1/2 md:w-auto',
                                  {
                                    'no-right-border':
                                      tableField.removeRightBorder,
                                    'no-left-border':
                                      tableField.removeLeftBorder,
                                  }
                                )}
                              >
                                {tableField.content}
                              </th>
                            )
                          })}
                        </tr>
                        {table.rows?.map((row, l) => {
                          return (
                            <tr key={l}>
                              {row.map((tableField, k) => {
                                return (
                                  <td
                                    key={k}
                                    className={classNames(
                                      'bg-neutral-light-gray text-neutral-dark-gray font-medium relative px-2 py-5 align-middle border border-white w-1/2 md:w-auto',
                                      {
                                        'no-right-border':
                                          tableField.removeRightBorder,
                                        'no-left-border':
                                          tableField.removeLeftBorder,
                                        feature: tableField.featured,
                                      }
                                    )}
                                  >
                                    {/* <a-svg
                                            v-if="tableField.featured"
                                            src="icons/table-feature.svg"
                                            class="absolute top-0 left-0"
                                          /> */}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: tableField.content ?? '',
                                      }}
                                    />
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          )}
          {content?.additionalText && (
            <p
              className="mt-3.5 font-semibold text-p text-neutral-dark-gray"
              dangerouslySetInnerHTML={{ __html: content.additionalText }}
            />
          )}
          {content?.footnotes && (
            <p
              className="mt-4 c-table__footnotes md:mt-8"
              dangerouslySetInnerHTML={{ __html: content.footnotes }}
            />
          )}
        </div>
      </div>
      {content?.video &&
        content?.video[0] != null &&
        content.video[0].embeddedAsset != null &&
        content.video[0].embeddedAsset.url && (
          <MVideoModal
            open={modalOpen}
            video={content.video[0].embeddedAsset.url}
            onClose={closeModal}
          />
        )}
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-table__table th.rounded,
  .c-table__table td.rounded {
    border-radius: 8px 0 0 !important;
  }

  .c-table__table td.feature {
    font-weight: bold;
  }

  .c-table__table th.no-right-border,
  .c-table__table td.no-right-border {
    border-right: none;
  }

  .c-table__table th.no-left-border,
  .c-table__table td.no-left-border {
    border-left: none;
  }

  .c-table__table th.transparent,
  .c-table__table td.transparent {
    border: none;
    background-color: transparent;
  }
`

export const CTableFragments = {
  field: `
    fragment table on table_MatrixField {
      ... on table_main_BlockType {
        id
        typeHandle
        idName
        componentHeading: heading
        copy
        supText
        additionalText
        footnotes
        buttonText
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
      ... on table_desktopTable_BlockType {
        id
        typeHandle,
        heading {
          content
          removeLeftBorder
          removeRightBorder
          transparent
        }
        rows {
          ... on rows_BlockType {
            row {
              content
              removeLeftBorder
              removeRightBorder
              featured
            }
          }
        }
      }
      ... on table_mobileTable_BlockType {
        id
        typeHandle,
        heading {
          content
          removeLeftBorder
          removeRightBorder
        }
        rows {
          ... on rows_BlockType {
            row {
              content,
              removeLeftBorder
              removeRightBorder
              featured
            }
          }
        }
      }
    }
  `,
  field2: `
    fragment table2 on table2_MatrixField {
      ... on table2_main_BlockType {
        id
        typeHandle
        idName
        componentHeading: heading
        copy
        supText
        additionalText
        footnotes
        buttonText
        video {
          url
          title
          embeddedAsset {
            url
            title
          }
        }
      }
      ... on table2_desktopTable_BlockType {
        id
        typeHandle,
        heading {
          content
          removeLeftBorder
          removeRightBorder
          transparent
        }
        rows {
          ... on rows_BlockType {
            row {
              content,
              removeLeftBorder
              removeRightBorder
              featured
            }
          }
        }
      }
      ... on table2_mobileTable_BlockType {
        id
        typeHandle,
        heading {
          content
          removeLeftBorder
          removeRightBorder
        }
        rows {
          ... on rows_BlockType {
            row {
              content,
              removeLeftBorder
              removeRightBorder
              featured
            }
          }
        }
      }
    }
  `,
}
