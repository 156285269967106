import classNames from 'classnames'
import { useMemo } from 'react'
import css from 'styled-jsx/css'

export type CFootnotesBlock = {
  id?: string
  typeHandle?: string
  heading?: string
  items?: Array<{
    item: string
  }>
  copy?: string
}

type Props = {
  field: Array<CFootnotesBlock>
}

export const CFootnotes = ({ field }: Props) => {
  const content = useMemo(() => {
    return field ?? null
  }, [field])

  if (!content) {
    return <></>
  }

  return (
    <section className="pt-6 pb-10 c-footnotes md:py-14">
      <div className="o-container o-container--lg">
        {content.map((section, n) => {
          return (
            <div
              key={n}
              className={classNames({
                'mb-8 md:mb-12': section.typeHandle === 'single',
                hidden: section.typeHandle === 'single',
              })}
            >
              {section.typeHandle !== 'single' && (
                <h2 className="font-light text-p-lg text-neutral-dark-gray">
                  {section.heading}
                </h2>
              )}
              {section.typeHandle === 'list' && (
                <ol className="gap-8 mt-5 list-decimal list-inside c-footnotes__list text-p text-neutral-dark-gray md:columns-2">
                  {section.items?.map((item, m) => {
                    return <li key={m}>{item.item}</li>
                  })}
                </ol>
              )}
              {section.typeHandle === 'text' && (
                <div
                  className="mt-5 c-footnotes__copy text-p text-neutral-dark-gray"
                  dangerouslySetInnerHTML={{ __html: section.copy ?? '' }}
                />
              )}
              {n < content.length - 1 && section.typeHandle !== 'single' && (
                <hr className="h-px my-8 border-t md:my-12 border-neutral-dark-gray" />
              )}
            </div>
          )
        })}
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

const styles = css`
  .c-footnotes__list li::marker {
    font-weight: bold;
  }

  .c-footnotes__copy p {
    margin-top: 1.25rem;
  }

  .c-footnotes__copy p:first-of-type {
    margin-top: 0;
  }

  #dfuLink {
    text-decoration: underline;
  }
`

export const CFootnotesFragments = {
  field: `
    fragment footnotes on footnotes_MatrixField {
      ... on footnotes_single_BlockType {
        id
        typeHandle
        heading
      }
      ... on footnotes_list_BlockType {
        id
        typeHandle
        heading
        items {
          item
        }
      }
      ... on footnotes_text_BlockType {
        id
        typeHandle
        heading
        copy
      }
    }
  `,
}
