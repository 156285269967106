import { createClient, fetchExchange } from '@urql/core'
import { cacheExchange } from '@urql/exchange-graphcache'
import schema from './schema.json'

const schemaString = JSON.stringify(schema) || '{}'

const defaultClient = createClient({
  url: process.env.NEXT_PUBLIC_EVO_GRAPHQL_URL ?? '',
  exchanges: [
    fetchExchange,
    cacheExchange({ schema: JSON.parse(schemaString) }),
  ],
})

export { defaultClient }
